import React, { useState } from "react";
import { useLocalStorage } from "./useLocalStorage";

function App() {
  const [name, setName] = useState("");
  const [count, setCount] = useState("100");
  const [valError, setValError] = useState({ text: false });
  const [isShown, setIsShown] = useState("");
  const [counters, setCounters] = useLocalStorage("counters", {});
  const [countersInit, setCountersInit] = useLocalStorage("countersinit", {});

  const handleNew = (e) => {
    e.preventDefault();
    if (name !== "") {
      setCounters({ ...counters, [name]: count });
      setCountersInit({ ...counters, [name]: count });
      setName("");
      setValError({ text: false });
    }
  };

  const updateCounter = (counterName) => {
    setIsShown("");
    if (counters[counterName] <= 1) {
      const { [counterName]: tmp, ...newCounters } = counters;
      setCounters(newCounters);
      alert(`Great Job for finishing ${counterName}! 🎉`);
    } else {
      setCounters({ ...counters, [counterName]: counters[counterName] - 1 });
    }
  };

  const updateName = (e) => {
    if (e.target.value.length <= 20) {
      setName(e.target.value);
      setValError({ ...valError, text: false });
    } else setValError({ ...valError, text: true });
  };

  const resetCounters = () => {
    if (window.confirm("This will reset all your counters."))
      setCounters(countersInit);
  };

  const deleteCounters = () => {
    if (window.confirm("This will delete all your counters.")) {
      setCounters({});
      setCountersInit({});
    }
  };

  const getRepr = (counter) => {
    return `${counter}: ${counters[counter]} remain`;
  };

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="max-w-xl rounded overflow-hidden shadow-lg p-4">
        <div className="sm:px-20 sm:py-10">
          <div className="mb-10">
            <div className="font-bold text-3xl mb-2">Simple Counter</div>

            <p className="text-gray-700 text-base">
              Keep track of how many things you do in a day.
            </p>
          </div>

          {Object.keys(counters).map((counter, index) => (
            <button
              key={index}
              onClick={() => updateCounter(counter)}
              onMouseEnter={() => setIsShown(counter)}
              onMouseLeave={() => setIsShown("")}
              className="block focus:outline-none bg-gray-200 rounded-full px-4 py-2 font-semibold text-gray-700 mr-2 mt-2 text-md hover:bg-gray-300"
            >
              {isShown === counter
                ? `${counter}: Press to finish one`
                : getRepr(counter)}
            </button>
          ))}
          <div className="flex rounded-md shadow-sm mt-10">
            <label htmlFor="time" className="sr-only"></label>
            <input
              onChange={(e) => setCount(e.target.value)}
              type="text"
              defaultValue={100}
              className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 inline-flex items-center px-3  border-gray-400 bg-gray-200 text-indigo-500 text-lg w-20 border border-r rounded-r-none rounded-md"
            />
            <input
              className={`${
                valError.text
                  ? `focus:border-red-600 focus:ring-red-600 border-red-600 border-6`
                  : `focus:ring-indigo-500 focus:border-indigo-500`
              } focus:outline-none  flex-1 block w-full rounded-none border border-gray-400 px-4 text-lg py-2 `}
              type="text"
              placeholder="Pushups"
              value={name}
              onChange={(e) => updateName(e)}
            />

            {/* <select
              onChange={(e) => setCount(e.target.value)}
              id="time"
              className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 inline-flex items-center px-3 rounded-none border border-gray-400 bg-gray-200 text-indigo-500 text-lg"
            >
              {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((val) => (
                <option value={val} key={val}>{val}</option>
              ))}
            </select> */}
            <button
              onClick={handleNew}
              className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500  inline-flex items-center px-3 rounded-r-md border border-gray-400 bg-gray-200 text-indigo-500 text-lg"
            >
              Add
            </button>
          </div>
          <div className="flex mt-4 items-baseline w-full justify-between">
            <button
              type="button"
              className="focus:outline-none text-indigo-500 text-sm py-3 px-3 rounded-md hover:bg-red-100"
              onClick={resetCounters}
            >
              Reset
            </button>
            <button
              type="button"
              className="focus:outline-none text-red-600 text-sm py-3 px-3 rounded-md hover:bg-red-100"
              onClick={deleteCounters}
            >
              Delete All
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
